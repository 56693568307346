/***************************************/
/*                Projects             */
.project-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #2b2d42;
	min-height: 100vh;
}
.card-container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	align-items: stretch;
	background-color: #2b2d42;
}
.project-title {
	color: #fb3640;
	font-size: 3rem;
	padding: 4rem 0 2rem 0;
	text-align: center;
}
.project-card {
	width: 20rem;
	height: auto;
	flex-grow: 1;
	margin: 2rem;
	font-size: larger;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	border: none !important;
}
.card-body {
	overflow-y: auto;
	height: auto;
}

.project-icon {
	font-size: 2rem;
	color: #fb3640;
}
@media screen and (min-width: 1520px) {
	.project-card {
		width: 440px;
		height: auto;
		flex-grow: 0;
	}
}

@media screen and (max-width: 768px) {
	.project-card {
		width: 100%;
		height: auto;
	}
}
