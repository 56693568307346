/***************************************/
/*                Globals              */

h1 {
	color: #f8f7f9;
	font-size: 5rem !important;
}

h2 {
	font-family: "Bad Script", cursive;
	font-weight: 800 !important;
}

html {
	scroll-behavior: smooth;
	width: 100%;
}

a {
	text-decoration: none;
	color: #fb3640 !important;
}

a:hover {
	color: #fb3640 !important;
	text-decoration: none !important;
	filter: brightness(75%) !important;
}

.body {
	height: 95vh;
	background-color: #f8f7f9;
}

p {
	font-size: larger;
}

ul {
	text-decoration: none;
	margin: 0;
}

li {
	list-style-type: none;
	text-decoration: none;
}

.back-button {
	position: fixed; /* Fixed/sticky position */
	bottom: 50px; /* Place the button at the bottom of the page */
	right: 50px; /* Place the button 30px from the right */
	z-index: 99; /* Make sure it does not overlap */
	font-size: 5rem;
	color: #fb3640;
}

/***************************************/
/*                Opening              */

.opening-slide {
	background-color: #2b2d42;
	height: 100vh;
	width: 100%;
}

.intro {
	width: 100%;
	height: 80%;
	padding-left: 10%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
}

.indent {
	padding-left: 6rem;
}
/* .intro-right {
  width: 50%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */

.social-buttons {
	color: #f8f7f9;
}

/***************************************/
/*                About                */

.about-slide {
	background-color: #f8f7f9;
	min-height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.about-title {
	color: #fb3640;
	font-size: 3rem;
	padding-bottom: 2rem;
	text-align: center;
}

/***************************************/
/*                Skills                */
.skills-slide {
	background-color: #92dce5;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	width: 100%;
}
.skills-right {
	width: 50%;
}
.skills-left {
	display: flex;
	justify-content: center;
	width: 50%;
}

.skills-content {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.skills-title {
	color: #f8f7f9;
	font-size: 3rem;
	padding: 4rem 0 2rem 0;
	text-align: center;
}

.skills-paragraph {
	width: 100%;
	padding-left: 2.5rem;
}

/***************************************/
/*                Slider               */

/*Vertical Sliding*/
.slidingVertical {
	display: inline;
	text-indent: 8px;
}
.slidingVertical span {
	animation: topToBottom 10s linear infinite 0s;
	color: #fb3640;
	opacity: 0;
	height: 7rem;
	overflow: hidden;
	position: absolute;
}
.slidingVertical span:nth-child(2) {
	animation-delay: 1.5s;
}
.slidingVertical span:nth-child(3) {
	animation-delay: 3s;
}
.slidingVertical span:nth-child(4) {
	animation-delay: 4.5s;
}
.slidingVertical span:nth-child(5) {
	animation-delay: 6s;
}
.slidingVertical span:nth-child(6) {
	animation-delay: 7.5s;
}
/* .slidingVertical span:nth-child(7) {
  animation-delay: 6s;
}
.slidingVertical span:nth-child(8) {
  animation-delay: 7s;
} */
/*topToBottom Animation*/
@keyframes topToBottom {
	0% {
		opacity: 0;
	}
	5% {
		opacity: 0;
		transform: translateY(-50px);
	}
	10% {
		opacity: 1;
		transform: translateY(0px);
	}
	20% {
		opacity: 1;
		transform: translateY(0px);
	}
	25% {
		opacity: 0;
		transform: translateY(50px);
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@media screen and (max-width: 928px) {
	.card-container {
		flex-direction: column;
	}
	.skills-content {
		flex-direction: column;
	}
	.skills-left {
		width: 100%;
	}
	.skills-right {
		width: 100%;
	}
	h1 {
		font-size: 3rem !important;
	}
	.nav-item a {
		font-size: 1.5rem;
	}
	.skills-paragraph {
		padding-left: 0rem;
	}
}

@media screen and (max-width: 532px) {
	h1 {
		font-size: 2rem !important;
	}
	.nav-item a {
		font-size: 1rem;
	}
	.nav-item {
		padding-left: 0;
		padding-right: 0;
	}
	.indent {
		padding-left: 4rem;
	}
}

@media screen and (max-width: 400px) {
	h1 {
		font-size: 2rem !important;
	}
	.intro {
		padding-left: 1rem;
	}
	.nav-item {
		padding-left: 0;
		padding-right: 0;
	}
	.nav-item a {
		font-size: 1rem;
	}
	.back-button {
		bottom: 25px;
		right: 25px;
		font-size: 3rem;
	}
	.indent {
		padding-left: 2rem;
	}
}

@media screen and (max-width: 305px) {
	h1 {
		font-size: 1.4rem !important;
	}
	.intro {
		padding-left: 1rem;
	}
	.nav-item {
		padding-left: 0;
		padding-right: 0;
	}
	.nav-item a {
		font-size: 1rem;
		padding-left: 0.25rem;
	}
	.back-button {
		bottom: 25px;
		right: 25px;
		font-size: 3rem;
	}
	.indent {
		padding-left: 1rem;
	}
}
