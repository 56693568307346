@use '../../partial' as *;

/***************************************/
/*                Navbar               */
.Navbar {
	background-color: $navy;
	width: 100%;
	padding: 0;
	height: 4rem;
	font-size: 2rem;
	position: fixed;
	z-index: 5;
	font-family: "Bad Script", cursive;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.navlist {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 100%;
	margin: 0;
	padding: 0%;
}

.nav-buttons {
	@include flex-center-center;
	margin: 0 2rem 0 0;
	position: relative;
	z-index: 6;
}

.nav-menu {
	display: flex;
	flex-direction: row;
	position: relative;
	margin-left: 2rem;
}

.nav-item {
	padding: 0 0.75rem;
	color: $white;
	font-weight: bold;
	display: flex;
	justify-content: center;
}

.nav-item a {
	color: $red !important;
	vertical-align: middle;
	display: flex;
	justify-content: center;
}

.hamburger {
	display: none;
	margin-left: 2rem;
	font-size: 1.5rem;
	border: none;
	color: $red;
	background-color: $navy;
}

.hamburger :hover {
	filter: brightness(75%);
}
.hamburger svg :hover {
	filter: unset;
}
.nav-menu--active {
	display: flex;
}
.nav-menu--inactive {
	display: flex;
}
.slide-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	border-radius: 0 0 20% 20%;
	background-color: $navy;
}

@media screen and (max-width: 928px) {
	.nav-item a {
		font-size: 1.5rem;
	}
}

@media screen and (max-width: 532px) {
	.hamburger {
		display: flex;
	}
	.slide-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0 0.75rem 0.75rem 0.75rem;
		position: absolute;
		top: 0;
		left: 0;
	}
	.nav-menu {
		margin-left: 0rem;
		padding: 0rem;
		position: absolute;
		top: 4rem;
		left: 2rem;
		overflow: hidden;
	}
	.nav-menu--inactive {
		position: relative;
		left: 0rem;
		top: -15rem;
		transition: 1s;
	}
	.nav-menu--active {
		position: relative;
		transition: 1s;
	}
	.nav-item a {
		padding: 0;
	}
	.nav-item {
		padding: 0;
	}
	.nav-buttons {
		@include flex-center-center;
	}
	.social-buttons {
		margin: 0.75rem;
	}
}

@media screen and (max-width: 305px) {
	.nav-item {
		padding-left: 0;
		padding-right: 0;
	}
	.nav-item a {
		font-size: 1rem;
		padding-left: 0;
	}
	.social-buttons {
		margin: 0.5rem;
	}
}
